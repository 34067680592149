import React from "react";
import Hero from "../../sections/templates/landing7/Hero";
import Fact from "../../sections/templates/landing7/Fact";
import Content from "../../sections/templates/landing7/Content";
import CaseStudies from "../../sections/templates/landing7/CaseStudies";
import Reviews from "../../sections/templates/landing7/Reviews";
import Contact from "../../sections/templates/landing7/Contact";

import PageWrapper from "../../components/PageWrapper";

const LandingPage7 = () => {
  return (
    <>
      <PageWrapper headerDark footerDark>
        <Hero />
        <Fact />
        <Content />
        <CaseStudies />
        <Reviews />
        <Contact />
      </PageWrapper>
    </>
  );
};
export default LandingPage7;
